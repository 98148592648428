.Footer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.footer-container {
  position: absolute;
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #f1f4f8;
  width: 100%;
  font-family: sans-serif;
  font-weight: 400;
}

.footercontactInfo {
  display: flex;
  padding: 0px 60px;
  justify-content: space-around;
}

.quick-link-section ul {
  padding-left: 5px;
  list-style: none;
}

.quick-link-section ul li a {
  color: #435780;
  text-decoration: none;
  font-size: 16px;
  line-height: 1.42857143;
}

.quick-link-section ul li a:hover {
  text-decoration: underline;
}

.footer-container h4 {
  color: #2f2f2f8a;
  font-weight: 700;
  font-size: 16px;
}

.email-contact p {
  color: #2f2f2f8a;
  font-weight: 700;
  font-size: 16px;
}

.copyright-section {
  text-align: center;
}

.copyright-section p {
  color: #2f2f2f8a;
  font-weight: normal;
  font-size: 16px;
  margin: 1rem;
}

@media (max-width: 767px) {

  .contact-us-section,
  .quick-link-section {
    width: 100%;
  }

  .footercontactInfo {
    flex-wrap: wrap;
  }

  .footercontactInfo {
    padding: 0px 20px;
  }
}